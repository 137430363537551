<!-- 营销中心 平台营销工具 平台卡券管理 优惠券管理 新增 -->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <ykc-form-item label="优惠券名称" prop="cardCouponName">
      <ykc-input
        :disabled="isEdit"
        v-model="ruleForm.cardCouponName"
        placeholder="请输入优惠券名称"
        maxlength="50"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="优惠券面额" required>
      <div class="couponValue">
        <ykc-dropdown
          :disabled="isEdit"
          :clearable="false"
          style="width: 94px"
          :data="getDic.deduction_type"
          v-model="ruleForm.deductionType"></ykc-dropdown>
        <ykc-form-item prop="amountLimit">
          <ykc-input
            :disabled="isEdit"
            v-model="ruleForm.amountLimit"
            placeholder="请输入金额"
            maxlength="6"
            style="width: 160px">
            <template slot="prepend">满</template>
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item prop="faceValue">
          <ykc-input
            :disabled="isEdit"
            maxlength="6"
            v-model="ruleForm.faceValue"
            placeholder="请输入金额"
            style="width: 160px">
            <template slot="prepend">减</template>
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
      </div>
    </ykc-form-item>

    <ykc-form-item label="优惠券类型" prop="useType" required>
      <ykc-radio
        :disabled="isEdit"
        :data="getDic.use_type"
        v-model="ruleForm.useType"
        @change="changeUseType"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item label="优惠券数量" prop="stockNum" v-if="ruleForm.useType === '1'">
      <ykc-input
        :disabled="isEdit"
        v-model="ruleForm.stockNum"
        placeholder="请输入优惠券数量"
        maxlength="8">
        <template slot="append">张</template>
      </ykc-input>
    </ykc-form-item>
    <ykc-form-item label="有效时间类型" prop="effectiveTimeType" v-if="ruleForm.useType === '1'">
      <ykc-radio
        :disabled="isEdit"
        :data="getDic.use_time_type"
        v-model="ruleForm.effectiveTimeType"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item
      label="有效时间"
      prop="afterReceiveDay"
      v-if="ruleForm.effectiveTimeType === '1'">
      <ykc-input
        :disabled="isEdit"
        v-model="ruleForm.afterReceiveDay"
        placeholder="请输入相对时间"
        maxlength="3">
        <template slot="prepend">领取后</template>
        <template slot="append">天</template>
      </ykc-input>
    </ykc-form-item>
    <ykc-form-item label="有效时间" prop="termDate" v-else>
      <ykc-date-picker
        :disabled="isEdit"
        limitDate="limitBefore"
        format="yyyy-MM-dd HH:mm"
        v-model="ruleForm.termDate"></ykc-date-picker>
    </ykc-form-item>
    <ykc-form-item label="使用说明" prop="useInstructions">
      <ykc-input
        :disabled="isEdit"
        v-model="ruleForm.useInstructions"
        type="textarea"
        placeholder="请输入优惠券使用说明"
        maxlength="500"></ykc-input>
    </ykc-form-item>
    <template v-if="ruleForm.useType === '1'">
      <ykc-form-item prop="scopeType" label="可用维度" required>
        <ykc-radio
          :data="availableList"
          v-model="ruleForm.scopeType"
          @change="changeAvailable"></ykc-radio>
      </ykc-form-item>
      <ykc-form-item prop="stationIdList" label="选择电站" v-if="ruleForm.scopeType !== '4'">
        <ykc-tree
          v-model="checkAll"
          ref="operaTree"
          :data="treeData"
          :props="treeProps"
          :nodeKey="treeNodeKey"
          @check-change="handleTreeCheckChange"></ykc-tree>
      </ykc-form-item>
    </template>
  </ykc-form>
</template>
<script>
  import { marketManage, treeUtils, stationLabelApi } from '@/service/apis';
  import { sessionGetItem } from '@/utils';
  import regexpObj from '@/utils/regexp';

  export default {
    name: 'CouponManageAdd',
    props: {
      cardCouponId: {
        type: String,
      },
    },
    data() {
      return {
        availableList: [
          {
            id: '1',
            name: '按城市选择',
          },
          {
            id: '2',
            name: '按商户选择',
          },
          {
            id: '3',
            name: '按电站分组',
          },
          {
            id: '4',
            name: '全部电站',
          },
        ],
        checkAll: 'true',
        ruleForm: {
          useType: '1',
          scopeType: '1',
          cardCouponName: '', // 优惠券名称
          stockNum: '', // 总数
          deductionType: '1', // 优惠券类型 1电费 2服务费 3总电费 字典deduction_type
          faceValue: '', // 优惠金额/折扣比
          amountLimit: '', // 使用条件
          effectiveTimeType: '1', // 时间类型 use_time_type
          useInstructions: '', // 使用说明
          afterReceiveDay: '', // 使用时间几天后到期
          effectiveStartDate: '', // 期限开始时间
          effectiveEndDate: '', // 期限结束时间
          termDate: [],
          stationIdList: [],
        },
        rules: {
          cardCouponName: [
            { required: true, message: '请输入优惠券名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.nameType.errorTips.error('优惠券名称'),
            },
            { validator: this.onlyCheckName, trigger: 'blur' },
          ],
          faceValue: [
            { required: true, message: '请输入优惠券面额', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.amountNumberType.regexp,
              trigger: 'blur',
              message: '请输入正确的优惠券面额（范围：0.01~999.99）',
            },
            {
              validator: (rule, value, callback) => {
                if (
                  this.ruleForm.amountLimit !== '' &&
                  parseInt(value, 10) > parseInt(this.ruleForm.amountLimit, 10)
                ) {
                  callback('面额需小于等于费用抵扣条件');
                } else {
                  this.$refs.ruleForm.clearValidate('faceValue');
                  callback();
                }
              },
              trigger: 'blur',
            },
            {
              validator: (rule, value, callback) => {
                console.log(value);
                if (Number(value) > 999.99 || Number(value) < 0.01) {
                  callback('请输入正确的优惠券面额（范围：0.01~999.99）');
                } else {
                  this.$refs.ruleForm.clearValidate('faceValue');
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          stockNum: [
            { required: true, message: '请输入优惠券数量', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '优惠券数量',
                99999999
              ),
            },
          ],
          deductionType: [{ required: true, message: '请选择费用抵扣类型', trigger: 'change' }],
          amountLimit: [
            { required: true, message: '请输入费用抵扣条件', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.amountNumberType.regexp,
              trigger: 'blur',
              message: '请输入正确的费用抵扣条件（范围：0.01~999.99）',
            },
            {
              validator: (rule, value, callback) => {
                if (
                  this.ruleForm.faceValue !== '' &&
                  parseInt(value, 10) < parseInt(this.ruleForm.faceValue, 10)
                ) {
                  callback('费用抵扣条件需大于等于优惠券面额');
                } else {
                  this.$refs.ruleForm.clearValidate('amountLimit');
                  callback();
                }
              },
              trigger: 'blur',
            },
            {
              validator: (rule, value, callback) => {
                console.log(value);
                if (Number(value) > 999.99 || Number(value) < 0.01) {
                  callback('请输入正确的费用抵扣条件（范围：0.01~999.99）');
                } else {
                  this.$refs.ruleForm.clearValidate('amountLimit');
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          effectiveTimeType: [{ required: true, message: '请选择有效时间类型', trigger: 'change' }],
          afterReceiveDay: [
            { required: true, message: '请输入相对时间', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.integerNumberType.errorTips.error('相对时间', 999),
            },
          ],
          termDate: [{ required: true, trigger: 'change', message: '请选择活动时间' }],
          useInstructions: [
            { required: false, trigger: 'blur', message: '请输入优惠券使用说明' },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.textAreaType.errorTips.error('优惠券使用说明'),
            },
          ],
          stationIdList: [{ required: true, message: '请选择电站', trigger: 'change' }],
        },
        treeCityData: [],
        treeCityProps: {
          label: 'name',
          children: 'subTree',
          disabled: 'disabled',
        },
        treeCityNodeKey: 'id',
        treeOperatorData: [],
        treeOperatorProps: {
          label: 'stationName',
          children: 'stationTreeVOList',
          disabled: 'disabled',
        },
        treeOperatorNodeKey: 'stationId',
        treeStationData: [],
        treeStationProps: {
          label: 'name',
          children: 'childList',
          disabled: 'disabled',
        },
        treeStationNodeKey: 'id',
        getDic: {
          deduction_type: [
            { id: '1', name: '电费' },
            { id: '2', name: '服务费' },
            { id: '3', name: '总费用' },
          ],
          use_time_type: [
            { id: '1', name: '相对时间' },
            { id: '2', name: '绝对时间' },
          ],
          use_type: [
            { id: '1', name: '普通券' },
            { id: '2', name: '会员券' },
          ],
        },
      };
    },
    computed: {
      isEdit() {
        return !!this.cardCouponId;
      },
      treeData() {
        if (this.ruleForm.scopeType === '1') {
          return this.treeCityData;
        }
        if (this.ruleForm.scopeType === '2') {
          return this.treeOperatorData;
        }
        if (this.ruleForm.scopeType === '3') {
          return this.treeStationData;
        }
        return [];
      },
      treeProps() {
        if (this.ruleForm.scopeType === '1') {
          return this.treeCityProps;
        }
        if (this.ruleForm.scopeType === '2') {
          return this.treeOperatorProps;
        }
        if (this.ruleForm.scopeType === '3') {
          return this.treeStationProps;
        }
        return {
          label: 'name',
          children: 'subTree',
          disabled: 'disabled',
        };
      },
      treeNodeKey() {
        if (this.ruleForm.scopeType === '1') {
          return this.treeCityNodeKey;
        }
        if (this.ruleForm.scopeType === '2') {
          return this.treeOperatorNodeKey;
        }
        if (this.ruleForm.scopeType === '3') {
          return this.treeStationNodeKey;
        }
        return 'id';
      },
    },
    created() {
      this.getCouponAndTree();
    },
    methods: {
      changeUseType(val) {
        if (val === '2') {
          this.ruleForm.effectiveTimeType = '1';
          this.ruleForm.scopeType = '4';
        }
      },
      handleTreeCheckChange() {
        this.ruleForm.stationIdList = this.$refs.operaTree.getCheckedKeys(true);
        console.log('1111', this.ruleForm.stationIdList);
      },
      /**
       * 获取优惠券
       */
      getCouponAndTree() {
        const requests = [];
        const cityTreeRequest = treeUtils.findCityStationTree({});
        const operatorTreeRequest = treeUtils.operatorStations({});
        const stationTreeRequest = stationLabelApi.queryStationTree({});
        requests.push(cityTreeRequest);
        requests.push(operatorTreeRequest);
        requests.push(stationTreeRequest);
        Promise.all(requests)
          .then(([cityTreeData, operatorTreeData, stationTreeData]) => {
            this.treeCityData = cityTreeData;
            this.treeOperatorData = this.transOperatorStations(operatorTreeData);
            this.treeStationData = stationTreeData;
            if (this.isEdit) {
              this.getDetail();
            }
          })
          .catch(() => {
            this.treeCityData = [];
            this.treeOperatorData = [];
            this.treeStationData = [];
          });
      },
      // 处理商户数据
      transOperatorStations(listData = []) {
        const kayMap = { operatorName: 'stationName', operatorId: 'stationId' };
        const setEmpty = (data, keyMap) => {
          const objs = Object.keys(data).reduce((newData, key) => {
            const newKey = keyMap[key] || key;
            newData[newKey] = data[key];
            return newData;
          }, {});
          return objs;
        };

        return listData.map(item => {
          return setEmpty(item, kayMap);
        });
      },
      /**
       * 保存操作
       */
      // eslint-disable-next-line no-unused-vars
      async beforeDrawer(cb) {
        if (this.ruleForm.termDate.length === 2) {
          this.ruleForm.effectiveStartDate = `${this.ruleForm.termDate[0]}`;
          this.ruleForm.effectiveEndDate = `${this.ruleForm.termDate[1]}`;
        }
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const reqParams = {
              ...this.ruleForm,
              undertakerType: '1',
              cardCouponType: '2',
              operatorBy: sessionGetItem('accountNo'),
              undertakerId: 'qiehuanid',
            };
            console.log(reqParams);
            if (this.isEdit) {
              // 编辑
              reqParams.cardCouponId = this.cardCouponId;
              marketManage.edit(reqParams).then(() => {
                cb && cb();
                this.$emit('finish');
              });
            } else {
              marketManage.add(reqParams).then(() => {
                cb && cb();
              });
            }
          }
        });
      },
      /**
       * 活动名称校验
       */
      onlyCheckName(rule, value, callback) {
        if (this.isEdit) {
          callback();
          return;
        }
        marketManage
          .unique({
            cardCouponName: value,
            undertakerType: '1',
            cardCouponType: '2',
          })
          .then(() => {
            callback();
          })
          .catch(err => {
            console.log(err);
            callback(err.data?.resultDesc);
          });
      },
      changeAvailable() {
        // 清楚选择的电站数据
        this.ruleForm.stationIdList = [];
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        marketManage
          .detail({ cardCouponId: this.cardCouponId, undertakerType: '1', cardCouponType: '2' })
          .then(res => {
            console.log(res);
            this.ruleForm = {
              useType: res.useType,
              scopeType: res.scopeType,
              cardCouponName: res.cardCouponName, // 优惠券名称
              stockNum: res.stockNum, // 总数
              deductionType: res.deductionType, // 优惠券类型 1电费 2服务费 3总电费 字典deduction_type
              faceValue: res.faceValue, // 优惠金额/折扣比
              amountLimit: res.amountLimit, // 使用条件
              effectiveTimeType: res.effectiveTimeType, // 时间类型 use_time_type
              useInstructions: res.useInstructions, // 使用说明
              afterReceiveDay: res.afterReceiveDay, // 使用时间几天后到期
              effectiveStartDate: res.effectiveStartDate || '', // 期限开始时间
              effectiveEndDate: res.effectiveEndDate || '', // 期限结束时间
              termDate:
                res.effectiveStartDate && res.effectiveEndDate
                  ? [res.effectiveStartDate, res.effectiveEndDate]
                  : [],
              stationIdList: res.stationIdList || [],
            };
            this.$nextTick(() => {
              this.$refs.operaTree.setCheckedKeys(res.stationIdList || [], true);
            });
          });
      },
    },
  };
</script>
<style lang="scss">
  .has-time.ykc-date-picker,
  .has-time.hourHide {
    .el-picker-panel__footer .el-button.el-button--text {
      display: none;
    }
  }
  .el-select-dropdown__list {
    min-width: 94px;
    // font-size: 12px;
    // color: #000;
  }
  .couponValue {
    display: flex;
    justify-content: space-between;
    margin-bottom: -20px;
  }
</style>
