<!--
 * @Description: 私桩红包
-->
<template>
  <scroll-layout class="red-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              type="plain"
              @click="
                () => {
                  showDrawer = true;
                }
              ">
              新增
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <!-- 新增/编辑抽屉 -->
      <ykc-drawer
        title="私桩红包"
        :show.sync="showDrawer"
        :on-close="onAddDrawerClose"
        :before-cancel="onAddDrawerCancel">
        <template #footer>
          <div class="drawer-button-wrap">
            <template v-for="(btn, i) in drawerButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <div class="drawer-main-body" v-if="showDrawer">
          <AddOrEdit ref="addOrEdit"></AddOrEdit>
        </div>
      </ykc-drawer>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { privateRedPackApi } from '@/service/apis';
  import { code, offlineExport } from '@/utils';
  import AddOrEdit from './AddOrEdit.vue';

  export default {
    name: 'privateRedEnvelopeList',
    components: {
      AddOrEdit,
    },
    inject: ['reload'],
    data() {
      return {
        showDrawer: false,
        selectedRows: [], // 选中数据行
        modeData: [
          {
            id: '1',
            name: '单次',
          },
          {
            id: '2',
            name: '按月',
          },
        ], // 发放模式数据
        searchParams: {
          mode: '',
          redName: '',
        },

        tableTitle: '私桩红包',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '红包名称', prop: 'redPackName', minWidth: '200px' },
          {
            label: '单个金额',
            prop: 'amount',
            minWidth: '100px',
          },
          {
            label: '发放模式',
            prop: 'modeName',
            minWidth: '100px',
          },
          {
            label: '关联充电桩数',
            prop: 'pileCount',
            minWidth: '100px',
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return this.drawerType !== 'detail';
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },

          {
            label: '保存',
            enabled: () => {
              return !this.isEdit;
            },
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestList();
              });
            },
          },
        ],
      };
    },
    created() {
      this.requestList();
      this.initData();
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcInput',
            key: 'redPackName',
            label: '红包名称',
            placeholder: '请输入红包名称',
          },

          {
            comName: 'YkcDropdown',
            key: 'mode',
            label: '发放模式',
            placeholder: '请选择发放模式',
            data: this.modeData,
          },
        ];
      },
    },
    methods: {
      code,
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('asset:station:privatepile:del'),
            // enabled: () => true,
            render: (h, data, { row }) =>
              row.pileCount > 0 ? (
                <ykc-button type="text" disabled>
                  删除
                </ykc-button>
              ) : (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.doDelete(row);
                  }}>
                  删除
                </ykc-button>
              ),
          },
        ];
      },

      /**
       * 新增
       * */
      add() {
        this.id = null;
        this.parName = '';
        this.parCode = '';
        this.drawerType = 'add';
        this.isEdit = false;
        this.showDrawer = true;
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'station_private_pile_export',
            jsonNode: {
              stationType: 1,
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },

      /**
       * @desc 单个删除
       * */
      doDelete(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '是否确定删除红包？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            privateRedPackApi
              .delOne({ id: row.id })
              .then(res => {
                this.$message.success('删除成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },

      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            done(); // 关闭弹框
            drawerDone(); // 关闭抽屉
          },
        });
      },
      /**
       * 弹窗关闭
       * */
      onCLose() {
        this.show1 = false;
      },
      /**
       * @desc 关闭抽屉
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },

      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        privateRedPackApi
          .list(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
