<template>
  <ykc-form class="el-form-wrap">
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
        <ykc-form-item label="红包名称" prop="redPackName">
          <ykc-input v-model="ruleForm.redPackName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="单个金额" prop="amount">
          <ykc-input v-model="ruleForm.amount"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="发放模式" prop="mode">
          <ykc-radio
            v-model="ruleForm.mode"
            :data="[
              { id: '1', name: '单次' },
              { id: '2', name: '按月' },
            ]"></ykc-radio>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import { privateRedPackApi } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    props: {},
    name: 'AddOrEdit',
    data() {
      return {
        ruleForm: {
          redPackName: '',
          amount: '',
          mode: '1',
        },
        rules: {
          redPackName: [{ required: true, message: '请输入红包名称', trigger: 'blur' }],
          amount: [
            { required: true, message: '请输入单个金额', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.amountNumberType.regexp,
              message: regexpObj.regexp.input.amountNumberType.errorTips.error(),
              trigger: 'blur',
            },
          ],
          mode: [{ required: true, message: '请选择发放模式', trigger: 'change' }],
        },
      };
    },
    created() {
      // this.regionData = loadDicItem('region');
      // this.natureData = loadDicItem('nature');
      console.log(this.data);
    },

    methods: {
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              privateRedPackApi.addOne({ ...this.ruleForm }).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>
